import classNames from 'classnames';
import styles from './ReviewTag.module.scss';
import { LabelValue } from './ReviewTags';

type Props = {
  tag: LabelValue;
  isActive: boolean;
  onClick?: () => void;
};

export const ReviewTag = ({ tag, isActive, onClick }: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.tag, {
        [styles.tagActive]: isActive,
        [styles.isEditable]: !!onClick,
      })}
      key={tag.value}
      onClick={onClick}
    >
      {tag.label}
    </div>
  );
};
