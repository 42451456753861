import DataLoader from 'dataloader';
import {
  ClubMemberRole,
  MyClubRolesDocument,
  MyClubRolesQuery,
  MyClubRolesQueryVariables,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { Maybe } from 'types/generic';
import getApolloClient from 'utils/getApolloClient';
import { emitter, Payload } from './events';

export const clubRoleLoader = new DataLoader(
  async (keys: readonly string[]): Promise<Maybe<ClubMemberRole>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<MyClubRolesQuery, MyClubRolesQueryVariables>({
      query: MyClubRolesDocument,
      variables: { clubIds: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.myClubRoles;
  },
  { batch: true, cache: true }
);

export function useClubRoleLoader(clubId?: string | null) {
  const [data, setData] = useState<Maybe<ClubMemberRole>>();

  useEffect(() => {
    load();
    function applyNewData({ key, value }: Payload<Maybe<ClubMemberRole>>) {
      if (key === clubId) setData(value);
    }
    emitter.on('clubRole', applyNewData);
    return () => emitter.off('clubRole', applyNewData);
  }, [clubId]);

  async function load() {
    if (!clubId) return;
    const newVal = await clubRoleLoader.load(clubId);
    emitter.emit('clubRole', { key: clubId, value: newVal });
  }

  async function refetch() {
    if (!clubId) return;
    clubRoleLoader.clear(clubId);
    await load();
  }

  return { data, setData, refetch };
}
