import { FC, ReactElement, ReactNode } from 'react';

type Props = {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactElement;
};

/**
 * React component for conditionally wrapping children
 * Source: https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */
export const ConditionalWrapper: FC<Props> = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : <>{children}</>;
};
