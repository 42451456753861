import classNames from 'classnames';
import { Activity } from 'components/activity/Activity';
import SmallBook from 'components/book/Small';
import MomentContent from 'components/moment/Content';
import MomentShare from 'containers/share/MomentShare';
import ShareContentButton from 'containers/share/ShareContentButton';
import { ActivityGroupPartsFragment, MomentActivityFullFragment } from 'generated/graphql';
import React from 'react';
import { routes } from 'utils/routes';
import { getSharableMomentImageUrl } from 'utils/shareMedia';
import { isMomentGroup, notEmpty } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: MomentActivityFullFragment[];
  };
  avatarSticker?: JSX.Element;
};

export const MomentActivity = ({ activityGroup, avatarSticker }: Props): JSX.Element | null => {
  const activities = activityGroup.activities?.filter((activity) => Boolean(activity.data)) || [];
  const moments = activities.map((a) => a.data).filter(notEmpty);

  // Piet had a behaviour where data was not sent from the backend on his local machine
  if (!moments || moments?.length === 0) return null;

  if (activities.length === 0) return null;
  const firstActivity = activities[0];
  const profile = firstActivity.data?.profile;
  if (!firstActivity.data || !profile) return null;

  const collapsable = {
    height: 300,
    minItems: 2,
    buttonText: `Show all ${activityGroup.activity_count} highlights`,
  };
  const segments = [
    {
      text: firstActivity.data.profile.name,
      href: routes.profile,
      as: `/${firstActivity.data.profile.handle}`,
    },
  ];

  return (
    <Activity
      avatarSticker={avatarSticker}
      collapsable={collapsable}
      profile={profile}
      segments={segments}
      activityGroup={activityGroup}
      renderContent={(group) => {
        if (isMomentGroup(group)) {
          return <MomentActivities activities={group.activities} />;
        }
      }}
    />
  );
};

type ContentProps = {
  activities: Array<MomentActivityFullFragment>;
  hideBook?: boolean;
};

export const MomentActivities = ({ activities, hideBook }: ContentProps): JSX.Element => {
  return (
    <>
      {activities.map((activity, index) => {
        if (!activity.data) return null;
        const href = `/[handle]/book/[bookSlug]`;
        const as = `/${activity.data.profile.handle}/book/${activity.data.book.slug}`;

        return (
          <div
            key={activity.id}
            className={classNames({
              ['mb-10']: index < activities.length - 1,
            })}
          >
            <MomentContent
              key={activity?.data?.id}
              moment={activity.data}
              gradientColors={activity.data?.book.gradientColors?.filter(notEmpty)}
            />
            {!hideBook && (
              <div className="mt-4">
                <SmallBook book={activity.data.book} href={href} as={as} />
              </div>
            )}
            <div className="mt-4">
              <ActivityReactionBar
                activity={activity}
                shareButton={
                  <ShareContentButton
                    reactionBarButton
                    shareImageUrl={(format) =>
                      activity.data &&
                      getSharableMomentImageUrl(
                        activity.data.profile.handle,
                        activity.data.book.slug,
                        activity.data.id,
                        format
                      )
                    }
                    renderImagePreview={activity.data && <MomentShare moment={activity.data} />}
                    shareInfo={{
                      path: `/activity/${activity.id}`,
                    }}
                  />
                }
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
