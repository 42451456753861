import DataLoader from 'dataloader';
import {
  IsSubscribedToActivityUpdatesBulkDocument,
  IsSubscribedToActivityUpdatesBulkQuery,
  IsSubscribedToActivityUpdatesBulkQueryVariables,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';

export const subscriptionLoader = new DataLoader(
  async (keys: readonly string[]): Promise<boolean[]> => {
    const client = getApolloClient();

    const { data } = await client.query<
      IsSubscribedToActivityUpdatesBulkQuery,
      IsSubscribedToActivityUpdatesBulkQueryVariables
    >({
      query: IsSubscribedToActivityUpdatesBulkDocument,
      variables: { activityIds: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.isSubscribedToActivityUpdatesBulk;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: string): string => `activitySubscription:${key}`,
  }
);

export function useSubscriptionLoader(activityId?: string | null) {
  const [data, setData] = useState<boolean>(false);

  useEffect(() => {
    if (!activityId) return;
    subscriptionLoader.load(activityId).then((s) => {
      setData(s);
    });
  }, [activityId]);

  return {
    data,
    setData,
    refetch: async () => {
      if (!activityId) return;
      subscriptionLoader.clear(activityId);
      const data = await subscriptionLoader.load(activityId);
      setData(data);
    },
  };
}
