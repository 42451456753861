import { ExtendedReactionBar } from 'containers/ReactionBar/ExtendedReactionBar';
import { ReactionTarget } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { ReactNode } from 'react';
import { ActivityObjectType, LiteralActivity } from 'types/activity';
import ActivityOptions from './ActivityOptions';

type Props = {
  activity: LiteralActivity;
  shareButton: ReactNode;

  // No idea what activityFunction is supposed to mean, please explain
  // todo: please refactor this
  activityFunction?: () => void;
};

export const ActivityReactionBar = ({ activity, shareButton, activityFunction }: Props) => {
  const { isSignedIn } = useAuthContext();
  const { targetId, targetType } = getTarget(activity);

  return (
    <ExtendedReactionBar
      targetId={targetId}
      targetType={targetType}
      shareButton={shareButton}
      comments={activity.comments}
      commentCount={activity.commentCount}
      likeCount={activity.likeCount}
      optionsButton={
        <>
          {isSignedIn && activity.id && activity.data && (
            <ActivityOptions activityFunction={activityFunction} activity={activity} />
          )}
        </>
      }
      trackingTarget={activity.object_type as ActivityObjectType}
    />
  );
};

const getTarget = (activity: LiteralActivity): { targetId: string; targetType: ReactionTarget } => {
  if (activity.object_type === 'bookReview')
    return { targetId: activity.object!, targetType: ReactionTarget.Review };
  if (activity.object_type === 'moment')
    return { targetId: activity.object!, targetType: ReactionTarget.Moment };
  return { targetId: activity.id!, targetType: ReactionTarget.Activity };
};
