import { LinkSimple } from 'ui/generic';
import { notEmpty } from 'utils/typeGuards';
import styles from './ActivityHeadline.module.scss';

const SEPARATOR = '//SEPARATOR//';

export type ActivityHeadlineSegment =
  | {
      text: string;
      href: string;
      as: string;
    }
  | {
      text: string;
      remove: boolean;
    };

type Props = {
  message?: string | null;
  segments: ActivityHeadlineSegment[];
};

export const ActivityHeadline = ({ message, segments }: Props): JSX.Element | null => {
  if (!message) return null;

  let newMessage = message;
  for (const segment of segments) {
    newMessage = splitAtIndex(newMessage, segment.text);
  }
  const messageFragments = newMessage.split(SEPARATOR);

  const children = messageFragments.filter(notEmpty).map((x, i) => {
    const match = segments.find((s) => s.text === x);
    if (match) {
      if ('href' in match) {
        return (
          <LinkSimple key={i} href={match.href} as={match.as} variant="bold">
            {match.text}
          </LinkSimple>
        );
      }

      if (match.remove) {
        return '';
      }
    }
    return x;
  });

  return <span className={styles.text}>{children}</span>;
};

function splitAtIndex(text: string, subtext: string, separator = SEPARATOR) {
  const start = text.indexOf(subtext);
  const end = start + subtext.length;
  if (start > -1) {
    const result =
      text.substring(0, start) + separator + text.substring(start, end) + separator + text.substring(end);
    return result;
  }
  return text;
}
