import { DropdownMobileAndDesktop } from 'components/basics/Dropdowns/DropdownMobileAndDesktop';
import OptionsButton from 'components/reaction/OptionsButton';
import { MomentMenuOptionsItems } from 'containers/Moment/MomentMenuOptions';
import { useSubscriptionLoader } from 'dataloaders/subscriptionLoader';
import { useSetActivitySubscriptionMutation } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { LiteralActivity } from 'types/activity';
import { NotificationOffIcon, NotificationOnIcon } from 'ui/icons';
import { ActionItem, ActionList } from 'ui/specific/actionList';
import { isBookReviewActivity, isClubPostActivity, isMomentActivity } from 'utils/typeGuards';
import { ReviewMenuOptionsItems } from '../ReviewMenuOptions';
import { ActivityOptionsClubPost } from './ActivityOptionsClubPost';

type Props = {
  activity: LiteralActivity;
  // No idea what activityFunction is supposed to mean, please explain
  // todo: please refactor this
  activityFunction?: () => void;
};

const ActivityOptions = ({ activity, activityFunction }: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const { data: isSubscribed, refetch } = useSubscriptionLoader(activity.id);
  const [setActivitySubscription] = useSetActivitySubscriptionMutation();
  const { smScreen } = useScreenSize();

  const toggleSubscriptionStatus = async () => {
    const { data } = await setActivitySubscription({
      variables: {
        activityId: activity.id!,
        isSubscribed: !isSubscribed,
      },
    });
    if (data) {
      await refetch();
    }
  };

  const renderAdditionalOptions = () => {
    if (!activity?.data) return null;
    if (isMomentActivity(activity)) {
      return (
        <MomentMenuOptionsItems
          moment={activity.data}
          profile={activity.data.profile}
          book={activity.data.book}
          closeDropdown={() => {
            setOpen(false);
          }}
        />
      );
    } else if (isBookReviewActivity(activity)) {
      return (
        <ReviewMenuOptionsItems
          profile={activity.data.profile}
          book={activity.data.book}
          review={activity.data}
          closeDropdown={() => {
            setOpen(false);
          }}
        />
      );
    } else if (isClubPostActivity(activity)) {
      return (
        <ActivityOptionsClubPost
          post={activity.data}
          setEditMode={() => {
            activityFunction && activityFunction();
            setOpen(false);
          }}
        />
      );
    }
    return null;
  };

  return (
    <DropdownMobileAndDesktop
      isOpen={isOpen}
      setOpen={setOpen}
      button={<OptionsButton />}
      isMobile={smScreen}
      placement="bottom-end"
    >
      <ActionList>
        {renderAdditionalOptions()}
        <ActionItem
          item={{
            title: isSubscribed
              ? 'Turn off notifications for this activity'
              : 'Turn on notifications for this activity',
            icon: isSubscribed ? <NotificationOffIcon /> : <NotificationOnIcon />,
            onClick: toggleSubscriptionStatus,
          }}
        />
      </ActionList>
    </DropdownMobileAndDesktop>
  );
};

export default ActivityOptions;
