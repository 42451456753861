import classNames from 'classnames';
import { ConditionalWrapper } from 'components/layout/ConditionalWrapper';
import { ProfilePartsFragment } from 'generated/graphql';
import Link from 'next/link';
import { ReactNode } from 'react';
import { ActivityGroupFull } from 'types/activity';
import { Avatar, RelativeDateLink } from 'ui/generic';
import { routes } from 'utils/routes';
import styles from './Activity.module.scss';
import { ActivityHeadline, ActivityHeadlineSegment } from './ActivityHeadline';
import { CollapsableGroup } from './CollapsableGroup';

type Props = {
  activityGroup: ActivityGroupFull;
  renderContent: (activity: ActivityGroupFull) => ReactNode | null;
  segments: ActivityHeadlineSegment[];
  profile: ProfilePartsFragment;
  avatarSticker?: JSX.Element;
  collapsable: {
    buttonText: string;
    height: number;
    minItems?: number;
  };
  dateOverride?: string | Date;
};

export const Activity = ({
  activityGroup,
  renderContent,
  segments,
  profile,
  collapsable,
  avatarSticker,
  dateOverride,
}: Props): JSX.Element | null => {
  if (activityGroup.activities.length === 0) return null;

  const activity = activityGroup.activities[0];

  return (
    <div className={classNames(styles.container)}>
      <ConditionalWrapper
        condition={activityGroup.activities.length >= (collapsable.minItems || 3)}
        wrapper={(children) =>
          activityGroup.id ? (
            <CollapsableGroup {...collapsable} id={activityGroup.id}>
              {children}
            </CollapsableGroup>
          ) : (
            <></>
          )
        }
      >
        <div className={styles.header}>
          <div className={styles.avatar}>
            <Link href="/[handle]" as={`/${profile.handle}`}>
              <a>
                <Avatar avatarSticker={avatarSticker} profile={profile} />
              </a>
            </Link>
          </div>
          <div className={styles.info}>
            <div className={styles.text}>
              <ActivityHeadline message={activityGroup.message} segments={segments} />
            </div>
            <div className={styles.date}>
              <Link href={routes.activity} as={`/activity/${activity.id}`} passHref>
                <RelativeDateLink date={dateOverride || activity.data?.createdAt} />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.content}>{renderContent(activityGroup)}</div>
      </ConditionalWrapper>
    </div>
  );
};
