import classNames from 'classnames';
import * as React from 'react';
import { forwardRef, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { noop } from 'utils/noop';
import { getRandomId } from 'utils/random';
import styles from './Rating.module.scss';

interface StarIconProps {
  value: null | number;
  index: number;
  width: number;
  height: number;
  [props: string]: React.ReactNode;
}

/**
 * @deprecated use `ui/generic/Star` instead
 */
export const StarIcon = ({
  value = null,
  index = 0,
  width = 40,
  height = 40,
  ...props
}: StarIconProps): JSX.Element => {
  const [gradientId, setGradientId] = useState<string>();

  useEffect(() => {
    setGradientId('gradient' + getRandomId());
  }, []);

  const offset = useMemo(() => {
    if (!value) return 0;
    if (value >= index && value < index + 1) {
      return value - index;
    }
    if (value >= index) return 1;
    if (value < index) return 0;
  }, [value]);

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.3084 7.56332C19.5475 6.82542 20.5915 6.82541 20.8306 7.56332L23.2001 14.8772C23.307 15.2071 23.6143 15.4306 23.9612 15.4306L31.6335 15.4309C32.4079 15.4309 32.7305 16.4213 32.1047 16.8774L25.8936 21.4039C25.6141 21.6076 25.4972 21.9679 25.6037 22.2969L27.975 29.6177C28.2138 30.3551 27.3693 30.9673 26.7428 30.5108L20.5406 25.9914C20.2599 25.7869 19.8791 25.7869 19.5984 25.9914L13.3962 30.5108C12.7697 30.9673 11.9252 30.3551 12.164 29.6177L14.5353 22.2969C14.6418 21.9679 14.5249 21.6076 14.2454 21.4039L8.03433 16.8774C7.40853 16.4213 7.73111 15.4309 8.50548 15.4309L16.1778 15.4306C16.5247 15.4306 16.832 15.2071 16.9389 14.8772L19.3084 7.56332Z"
        strokeWidth="1.6"
        style={{ fill: `url(#${gradientId})` }}
      />
      <defs>
        <linearGradient y2="0%" x2="100%" y1="0%" x1="0%" id={`${gradientId}`} className={styles.gradient}>
          <stop className="part1" offset={offset} />
          <stop className="part2" offset="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

interface RatingInputProps {
  onChange: (index: number) => void;
  value: number | null;
  stars?: number;
  size?: number;
  className?: string;
}

function parseRatingFromString(input: string) {
  const float = parseFloat(input);
  if (float < 0) return 0;
  if (float > 5) return 5;
  return float;
}

/**
 * @deprecated use `RatingInputVertical` instead
 * tood: or refactor into a single `Rating` input component
 */
const RatingInput = ({
  onChange = noop,
  value = null,
  stars = 5,
  size = 40,
}: RatingInputProps): JSX.Element => {
  const [hoverValue, setHoverValue] = React.useState<number>();
  const [rawValue, setRawValue] = React.useState<string>(value?.toString() || '');
  const isMobile = window.matchMedia('(max-width: 599px)').matches;

  const handleClick = (newRating: number) => {
    onChange(newRating);
    setRawValue(newRating.toFixed(1));
  };

  return (
    <div
      className={classNames(styles.ratingInputContainer, {
        [styles.noValue]: !value && !hoverValue,
        [styles.hovering]: !!hoverValue,
      })}
    >
      <span className={styles.container}>
        {[...Array(stars)].map((_, index: number) => (
          <span
            key={index}
            className={styles.starIcon}
            style={{
              width: size - 8,
              height: size,
            }}
          >
            <span
              className={classNames(styles.starIconHalf, styles.starIconHalf1)}
              {...(!isMobile && {
                onMouseEnter: () => setHoverValue(index + 0.5),
                onMouseLeave: () => setHoverValue(undefined),
              })}
              onClick={() => setTimeout(() => handleClick(index + 0.5), 100)}
            />
            <span
              className={classNames(styles.starIconHalf, styles.starIconHalf2)}
              {...(!isMobile && {
                onMouseEnter: () => setHoverValue(index + 1),
                onMouseLeave: () => setHoverValue(undefined),
              })}
              onClick={() => setTimeout(() => handleClick(index + 1), 100)}
            />
            <StarIcon value={hoverValue || value} index={index} width={size} height={size} />
          </span>
        ))}
      </span>
      <div className={styles.textInputContainer}>
        {(value && value > 0) || hoverValue ? (
          <>
            <input
              className={styles.textInput}
              type="number"
              min={0}
              max={5}
              step={0.01}
              value={hoverValue?.toFixed(1) || rawValue}
              onChange={(el) => {
                setRawValue(el.target.value);
              }}
              onBlur={(el) => {
                const newVal = parseRatingFromString(el.target.value);
                setRawValue(newVal.toString());
                onChange(newVal);
              }}
            />
            / 5
          </>
        ) : (
          <div>No rating</div>
        )}
      </div>
    </div>
  );
};
RatingInput.displayName = 'RatingInput';

type RatingProps = {
  initValue: number;
  stars?: number;
  size?: number;
} & HTMLAttributes<HTMLDivElement>;

/**
 * @deprecated use `ui/generic/Rating` instead
 */
const Rating = forwardRef<HTMLSpanElement, RatingProps>(
  ({ initValue, stars = 5, size = 40, ...props }: RatingProps, ref): JSX.Element => {
    return (
      <span {...props} style={{ display: 'flex' }} ref={ref}>
        {[...Array(stars)].map((_, index: number) => (
          <span key={index} className={styles.starIcon} style={{ width: size - size / 5, height: size }}>
            <StarIcon value={initValue} index={index} width={size} height={size} />
          </span>
        ))}
      </span>
    );
  }
);

Rating.displayName = 'Rating';

export { Rating, RatingInput };
