import classnames from 'classnames';
import { Rating } from 'components/basics/Rating';
import Spoiler from 'components/basics/Spoiler';
import { BookPartsFragment, ProfilePartsFragment, ReviewPartsFragment } from 'generated/graphql';
import { useLongPress } from 'hooks/useLongPress';
import Link from 'next/link';
import { ReactNode, useCallback, useMemo } from 'react';
import { Cover, Date, Tooltip } from 'ui/generic';
import { RichTextViewer } from 'ui/specific/richText/RichTextViewer';
import { noop } from 'utils/noop';
import styles from './Review.module.scss';
import { ReviewTag } from './ReviewTag';
import { getTagsIn } from './ReviewTags';

type Props = {
  book?: BookPartsFragment;
  review?: ReviewPartsFragment;
  profile?: ProfilePartsFragment;
  showBookCover?: boolean;
  linkBookCover?: boolean;
  hideTitle?: boolean;
  button?: ReactNode;
  onLongPress?: () => void;
};

export const BookReview = ({
  review,
  book,
  profile,
  showBookCover = false,
  linkBookCover = false,
  hideTitle = false,
  button,
  onLongPress = noop,
}: Props): JSX.Element | null => {
  const longPress = useLongPress(onLongPress);

  const reviewContent = useMemo(() => {
    if (review?.json) {
      return review?.json;
    } else if (review?.text) {
      return review?.text;
    } else return undefined;
  }, [review]);

  const tags = useMemo(() => {
    if (!review?.tags) return [];
    return getTagsIn(review.tags);
  }, [review?.tags]);

  const renderCover = useCallback(() => {
    if (!book || !profile) return null;

    if (!linkBookCover) {
      return (
        <div className={styles.coverHeight}>
          <Cover book={book} stretch="vertical" assetSize="300" />
        </div>
      );
    }

    return (
      <Link href={`/[handle]/book/[bookSlug]`} as={`/${profile.handle}/book/${book.slug}`}>
        <a className={styles.coverHeight}>
          <Cover book={book} stretch="vertical" assetSize="300" />
        </a>
      </Link>
    );
  }, [linkBookCover, book, profile]);

  if (!review || !profile || !book) return null;

  return (
    <div className={classnames(styles.container, 'my-4')}>
      {showBookCover && (
        <div {...longPress()} className={styles.coverInner}>
          {renderCover()}
        </div>
      )}
      {!hideTitle && (
        <>
          <h3 className="sectionHeader italic" id={review.id}>
            {profile.name}&apos;s review of
          </h3>
          <h3 className="sectionHeader" style={{ marginBottom: 10 }}>
            {book.title}
            {book.authors && book.authors.length > 0 && (
              <>
                <i> by </i>
                {book.authors[0].name}
              </>
            )}
          </h3>
        </>
      )}
      {hideTitle && (
        <>
          <h3 className="sectionHeader italic" id={review.id}>
            {profile.name}&apos;s review
          </h3>
        </>
      )}

      {review.rating > 0 && (
        <Tooltip
          text={`${review.rating} ${review.rating === 1 ? 'star' : 'stars'}`}
          render={(ref) => <Rating ref={ref} initValue={review.rating} className="mt-4" />}
        />
      )}

      {!button && (
        <Link href={`/[handle]/book/[bookSlug]`} as={`/${profile.handle}/book/${book.slug}#${review.id}`}>
          <a className="mt-4">
            <Date date={review.createdAt} />
          </a>
        </Link>
      )}

      {tags && tags.length > 0 && (
        <div className={styles.tagsContainer}>
          {tags.map((tag) => (
            <ReviewTag key={tag.value} tag={tag} isActive={true} />
          ))}
        </div>
      )}

      {button && button}

      <div className={styles.reviewBodyContainer}>
        {reviewContent && (
          <Spoiler hideIt={review.spoiler} text="review">
            <div className={classnames('body-large', 'mt-4', styles.reviewBody)}>
              <RichTextViewer content={reviewContent} />
            </div>
          </Spoiler>
        )}
      </div>
    </div>
  );
};
