import { useSessionStorage } from 'hooks/useSessionStorage';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from 'ui/generic';
import styles from './CollapsableGroup.module.scss';

type Props = {
  id: string;
  children: ReactNode;
  buttonText?: string;
  height?: number;
};

export const CollapsableGroup = ({
  children,
  buttonText = 'Show all activities',
  height = 588,
  id,
}: Props): JSX.Element => {
  const [isExpanded, setExpanded] = useSessionStorage(`timeline-group-${id}`, false);
  const [isButtonNeeded, setIsButtonNeeded] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (element.current && element.current.scrollHeight > height) {
        setIsButtonNeeded(true);
      }
    });
  }, [element.current]);

  return (
    <>
      <div
        className={`${styles.container} ${isButtonNeeded && !isExpanded ? styles.limited : ''}`}
        ref={element}
        style={{
          maxHeight: `${isExpanded ? 'none' : `${height}px`}`,
        }}
      >
        {children}

        {isButtonNeeded && !isExpanded && (
          <div className={styles.button}>
            <Button mini onClick={() => setExpanded((state) => !state)}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CollapsableGroup;
