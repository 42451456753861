import { useClubRoleLoader } from 'dataloaders/clubRoleLoader';
import { ClubPostPartsFragment, ContentFlagTarget, useDeleteClubPostMutation } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { useMemo } from 'react';
import { DeleteIcon, FlagIcon, PenIcon } from 'ui/icons';
import { ActionItem } from 'ui/specific/actionList';

type ClubPostOptionsItems = {
  setEditMode?: () => void;
  post: ClubPostPartsFragment;
};

export const ActivityOptionsClubPost = ({ setEditMode, post }: ClubPostOptionsItems): JSX.Element | null => {
  const { profile: currentProfile } = useAuthContext();
  const [deleteClubPost] = useDeleteClubPostMutation();
  const { setContentFlagModal } = useGlobalModalContext();

  const { data: myRole } = useClubRoleLoader(post.clubId);

  const canDelete = useMemo(() => {
    return (currentProfile && currentProfile.id === post.profileId) || myRole === 'Owner';
  }, [currentProfile]);

  const canUpdate = useMemo(() => {
    return currentProfile && currentProfile.id === post.profileId;
  }, [currentProfile]);

  const protectedDelete = () => {
    const areYouSure = confirm('This is permanent. Are you sure you want to delete this post?');
    if (areYouSure) {
      deleteClubPost({
        variables: { postId: post.id },
        update: (store) => {
          const clubPost = { __typename: 'ClubPost', id: post.id };
          store.evict({ id: store.identify(clubPost) });
        },
      });
    }
  };

  return (
    <>
      {canUpdate && setEditMode && (
        <ActionItem item={{ title: 'Edit Post', onClick: () => setEditMode(), icon: <PenIcon /> }} />
      )}
      {canDelete && (
        <ActionItem item={{ title: 'Delete post', onClick: () => protectedDelete(), icon: <DeleteIcon /> }} />
      )}
      {canUpdate || (canDelete && <ActionItem item={{ divider: 'full' }} />)}

      <ActionItem
        item={{
          title: 'Report',
          icon: <FlagIcon />,
          onClick: () =>
            setContentFlagModal({
              targetId: post.id,
              targetType: ContentFlagTarget.ClubPost,
              snapshot: JSON.stringify(post),
            }),
        }}
      />

      <ActionItem item={{ divider: 'full' }} />
    </>
  );
};
